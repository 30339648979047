import { useQuery } from 'react-query'
import API_INSTANCE from '../services/api'
import endpoints from '../services/endpoints'
import { apiResponseSimplify } from '../helpers/utils'

export const useOrders = (queryParams: string = '') => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['orders'],
    () => API_INSTANCE.get(`${endpoints.GET_ORDERS}?${queryParams}`),
    {
      keepPreviousData: true,
      select: (response) => {
        return apiResponseSimplify(response, true)
      },
    }
  )

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  }
}
