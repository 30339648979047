import { useQuery } from 'react-query'
import { apiResponseSimplify } from '../helpers/utils'
import endpoints from './endpoints'
import API_INSTANCE from './api'

/**
 * Get instagram profile
 */
export const useGetInstagramProfile = (username: string) => {
  return useQuery(
    ['instauser', username],
    () => API_INSTANCE.get(`${endpoints.SCRAPE_IG_PROFILE}?user=${username}&is_profiling=1`),
    {
      enabled: false,
      retry: 0,
      select: (response) => {
        return apiResponseSimplify(response)
      },
    }
  )
}
