import { useMutation, useQuery } from 'react-query'
import { apiResponseSimplify } from '../helpers/utils'
import API_INSTANCE from './api'
import endpoints from './endpoints'
import { ICoupon } from '../types/coupon.types'
import { format } from 'react-string-format'

export const useGetCouponList = (queryParams: string = '') => {
  return useQuery(
    ['coupon-list', queryParams],
    () => API_INSTANCE.get(`${endpoints.COUPON}?${queryParams}`),
    {
      keepPreviousData: true,
      select: (response) => {
        return apiResponseSimplify(response, true)
      },
    }
  )
}

export const useAddCoupon = () => {
  return useMutation((request: ICoupon) =>
    API_INSTANCE.post(endpoints.COUPON, request)
  )
}

export const useUpdateCoupon = () => {
  return useMutation((request: ICoupon) =>
    API_INSTANCE.put(
      format(endpoints.UPDATE_COUPON, request?.id ?? ''),
      request
    )
  )
}

export const useDeleteCoupon = () => {
  return useMutation((id: number) =>
    API_INSTANCE.delete(format(endpoints.UPDATE_COUPON, id ?? ''))
  )
}

/**
 * Get coupon details
 */
export const useCheckCoupon = (code?: string | null) => {
  return useQuery(
    ['coupon', code],
    () => API_INSTANCE.get(endpoints.COUPON_CHECK + '?code=' + code),
    {
      enabled: false,
      keepPreviousData: false,
      select: (response) => {
        return apiResponseSimplify(response)
      },
    }
  )
}
