import { useMutation, useQuery } from 'react-query'

import { apiResponseSimplify } from '../helpers/utils'
import { TYPE_SUPER_ADMIN, TYPE_WORKER } from '../types/user.types'
import API_INSTANCE from './api'
import endpoints from './endpoints'

const authName = process.env.REACT_APP_AUTH_NAME ?? 'token'
const infoName = process.env.REACT_APP_INFO_NAME ?? 'user'

export function setAuth(value: any, hasUser: boolean = false) {
  if (value === undefined) {
    return
  }

  if (hasUser) {
    setCurrentUser(value.user)
    localStorage.setItem(authName, value.token)
    return
  }
  localStorage.setItem(authName, value.token)
}

export function getToken() {
  return localStorage.getItem(authName)
}

export function setCurrentUser(value: string | undefined) {
  if (value === undefined) {
    return
  }

  localStorage.setItem(infoName, JSON.stringify(value))
}

export function getCurrentUser() {
  const userStr = localStorage.getItem(infoName)
  if (!userStr) {
    return null
  }

  return JSON.parse(userStr)
}

export function removeAuth() {
  localStorage.removeItem(infoName)
  return localStorage.removeItem(authName)
}

export const isUserAdmin = (userInfo: any) => {
  if (!Array.isArray(userInfo?.roles)) {
    return false
  }
  return (
    (userInfo?.roles ?? []).filter((role: string) =>
      [TYPE_SUPER_ADMIN, TYPE_WORKER].includes(role)
    ).length > 0
  )
}

/**
 * Custom hook for login request using react-query
 * RQProp onSuccess: function (Fires if request is success)
 * All Request Error is being handled in App.tsx
 */
export const useLoginMutation = () => {
  return useMutation(
    (request: { email: string; password: string }) =>
      API_INSTANCE.post(endpoints.LOGIN, request),
    {
      onSuccess: (data: any) => {
        if (data?.data) {
          setAuth(data.data, !!data.data?.user)
        }
      },
    }
  )
}
/**
 * Reset Password Link
 */
export const useRecoverMutation = () => {
  return useMutation((request: { email: string }) =>
    API_INSTANCE.post(endpoints.RECOVERY, request)
  )
}

export const useGetUserInfo = () => {
  return useQuery('user-info', () => API_INSTANCE.get(endpoints.INFO), {
    enabled: false,
    select: (response) => {
      const simpleResponse = apiResponseSimplify(response)
      setCurrentUser(simpleResponse)
      return simpleResponse
    },
  })
}

/**
 * Register User
 */
export const useRegisterMutation = () => {
  return useMutation(
    (request: {
      name: string
      email: string
      password: string
      c_password: string
    }) => API_INSTANCE.post(endpoints.REGISTER, request)
  )
}

/**
 * Reset Password
 */
export const useResetPasswordMutation = () => {
  return useMutation(
    (request: {
      email: string
      password: string
      password_confirmation: string
      token: string
    }) => API_INSTANCE.post(endpoints.RESET, request)
  )
}

/**
 * Get User accounts
 */

export const useGetUserAccounts = () => {
  return useQuery(
    'user-accounts',
    () => API_INSTANCE.get(endpoints.GET_USER_ACCOUNTS),
    {
      refetchOnWindowFocus: false,
      select: (response) => {
        return apiResponseSimplify(response)
      },
    }
  )
}

/**
 * Get User accounts subscription
 */

export const useGetUserAccountsSubscriptions = () => {
  return useQuery(
    'user-accounts-subscriptions',
    async () => await API_INSTANCE.get(endpoints.GET_USER_SUBSCRIPTIONS),
    {
      enabled: true,
      select: (response) => {
        return apiResponseSimplify(response)
      },
    }
  )
}
