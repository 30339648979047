import { useMutation, useQuery } from 'react-query'
import { apiResponseSimplify } from '../helpers/utils'
import API_INSTANCE from './api'
import endpoints from './endpoints'
import { IPackage } from '../types/package.types'
import { format } from 'react-string-format'

export const useGetPackageList = (queryParams: string = '') => {
  return useQuery(
    ['package-list', queryParams],
    () => API_INSTANCE.get(`${endpoints.PACKAGE}?${queryParams}`),
    {
      keepPreviousData: true,
      select: (response) => {
        return apiResponseSimplify(response, true)
      },
    }
  )
}

export const useAddPackage = () => {
  return useMutation((request: IPackage) =>
    API_INSTANCE.post(endpoints.PACKAGE, request)
  )
}

export const useUpdatePackge = () => {
  return useMutation((request: IPackage) =>
    API_INSTANCE.put(
      format(endpoints.UPDATE_PACKAGE, request?.id ?? ''),
      request
    )
  )
}

export const useDeletePackage = () => {
  return useMutation((id: number) =>
    API_INSTANCE.delete(format(endpoints.UPDATE_PACKAGE, id ?? ''))
  )
}

export const useGetPackageOptions = () => {
  return useQuery(
    ['package-options'],
    () => API_INSTANCE.get(endpoints.PACKAGE_OPTIONS),
    {
      enabled: false,
      keepPreviousData: true,
      select: (response) => {
        return apiResponseSimplify(response, true)
      },
    }
  )
}
