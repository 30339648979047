import { Box, Tab, Tabs } from '@mui/material'
import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { CheckScreenSize } from '../helpers/utils'
import PersonalInformation from '../components/dashboard/account-settings/personal-information'
import ChangePassWord from '../components/dashboard/account-settings/change-password'
import { AppContext } from '../Protected'
import { useUpdateUserMutation } from '../services/account.service'
import { useGetUserInfo } from '../services/auth.service'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className="panel"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AccountSettings = () => {
  const [value, setValue] = useState(0)

  const { userDetails, setNotification } = useContext(AppContext)

  const [personalInfo, setPersonalInfo] = useState({
    email: '',
    name: '',
    phone_number: '',
    id: 0,
  })

  const [passwordInfo, setPasswordInfo] = useState({
    password: '',
    current_password: '',
    confirm_password: '',
    id: 0,
  })

  const { isLoading, mutate: updateUser } = useUpdateUserMutation()
  const { refetch: refetchUserInfo } = useGetUserInfo()

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const defaultErrors = {
    name: false,
    email: false,
    phone_number: false,
  }

  const [errors, setErrors] = useState(defaultErrors)

  useEffect(() => {
    if (userDetails) {
      setPersonalInfo({
        email: userDetails.email,
        name: userDetails.name,
        phone_number: userDetails.phone_number,
        id: userDetails.id,
      })
      setPasswordInfo({ ...passwordInfo, id: userDetails.id })
    }
  }, [userDetails])

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setPersonalInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onChangePassword =
    (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      setPasswordInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }

  const validatePersonalInfo = () => {
    let dataFields = [
      {
        key: 'name',
        isValid: personalInfo.name !== '',
      },
      {
        key: 'email',
        isValid: personalInfo.email !== '',
      },
      {
        key: 'phone_number',
        isValid: true,
      },
    ]

    return {
      data: dataFields,
      isValid: dataFields.every((data) => data.isValid),
    }
  }

  const personalInfoSubmission = (event: SyntheticEvent) => {
    event.preventDefault()

    const validation = validatePersonalInfo()
    if (!validation.isValid) {
      setErrors(
        validation.data.reduce((prevField, field) => {
          return { ...prevField, [field.key]: !field.isValid }
        }, defaultErrors)
      )
      return
    }

    updateUser(personalInfo, {
      onSuccess: () => {
        setNotification('Account successfully updated.')
        refetchUserInfo()
      },
    })
  }

  const passwordInfoSubmission = async (event: SyntheticEvent) => {
    event.preventDefault()

    updateUser(passwordInfo, {
      onSuccess: () => {
        setNotification('Password successfully changed.')
      },
    })
  }

  const isDesktop = CheckScreenSize('(min-width: 1200px)')

  return (
    <Box
      flexGrow={1}
      p={!isDesktop ? '5px 10px 100px 20px' : '0px 40px 0px 40px'}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
        }}
      >
        <Box
          sx={{
            marginRight: isDesktop ? '20px' : '0px',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            orientation={isDesktop ? 'vertical' : 'horizontal'}
            sx={{
              background: '#FFFFFF',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              borderRadius: isDesktop ? '10px' : ' 10px 10px 0 0',
              '& .MuiTabs-indicator': {
                display: isDesktop ? 'none' : '',
                backgroundColor: '#261F72',
                height: '4px',
              },
              '& .billing': {
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              },
              '& button': {
                fontWeight: '700',
                fontSize: isDesktop ? '16px' : '14px',
                color: '#999',
                textTransform: 'capitalize',
                textAlign: 'left',
                padding: isDesktop ? '20px 40px' : '20px 20px',
                alignItems: 'unset',
                '&.Mui-selected': {
                  color: '#261F72',
                },
              },
            }}
          >
            <Tab
              label={isDesktop ? 'Personal Information' : 'Personal Info'}
              {...a11yProps(0)}
            />
            <Tab
              label={isDesktop ? 'Change Password' : 'Password'}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <Box
          sx={{
            background: '#FFFFFF',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            borderTop: isDesktop ? ' auto ' : '0px',
            borderRadius: isDesktop ? '10px' : ' 0 0 10px 10px ',
            flexGrow: '1',
            overflow: 'auto',
          }}
        >
          <TabPanel value={value} index={0}>
            <PersonalInformation
              data={personalInfo}
              onChange={onChange}
              errors={errors}
              onSubmit={personalInfoSubmission}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePassWord
              data={passwordInfo}
              onChange={onChangePassword}
              onSubmit={passwordInfoSubmission}
              isLoading={isLoading}
            />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountSettings
