import { Box } from '@mui/material'
import RectangularSkeleton from './skeletons/rectangular.skeleton'

export default function DashboardLoading({
  isDesktop,
}: {
  isDesktop: boolean
}) {
  return (
    <Box
      p={isDesktop ? '20px 40px' : '0px 20px'}
      display={isDesktop ? 'flex' : 'block'}
      gap={2}
    >
      {Array(isDesktop ? 4 : 2)
        .fill(null)
        .map((f, index: number) => (
          <RectangularSkeleton
            key={`header-skeleton-${index}`}
            width={isDesktop ? '25%' : '100%'}
            height="150px"
            sx={{
              borderRadius: '10px',
              marginBottom: isDesktop ? 0 : 2,
            }}
          />
        ))}
    </Box>
  )
}
