import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useState, useEffect } from 'react'
import RowWrapper from './rowWrapper'
import AlphabetFilter from './alpabetFilter'
import TableHeader from './tableHeader'
import TextSkeleton from '../skeletons/text.skeleton'

const DesktopTable = (props: any) => {
  const {
    rows,
    collapsible = false,
    withOptions = false,
    columns,
    contextMenu = [],
    getHeaderAddOns,
    title = '',
    collapsibleComponent,
    aditionalFilters,
    total,
    currentPage = 0,
    pageFrom = 0,
    pageTo = 0,
    pageCount = 0,
    entityLabel = 'records',
    onPageChange = () => {},
    tableMaxHeight = '73vh',
    isLoading,
    sortByOptions,
    onSort,
    sort,
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [activeRow, setActiveRow] = useState<any>(null)

  const isContextMenuOpen = Boolean(anchorEl)

  useEffect(() => {
    setTimeout(() => {
      getHeaderAddOns(
        <TableHeader
          title={title}
          aditionalFilters={aditionalFilters}
          total={total}
          sortByOptions={sortByOptions}
          onSort={onSort}
          sort={sort}
          isLoading={isLoading}
        />
      )
    }, 100)
  }, [total, sort, isLoading]) //eslint-disable-line

  const handleOpenContextMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setActiveRow(row)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseContextMenu = () => {
    setAnchorEl(null)
  }

  const handleAlphaFilter = (char: string) => {
    //search using alpha filter
  }

  let tableData = rows
  let tableColumns = columns
  if (isLoading) {
    const loadingData = Object.keys(columns).reduce(
      (acc, curr) => ({ ...acc, [curr]: '' }),
      {}
    )
    tableData = Array(3)
      .fill(null)
      .map(() => loadingData)

    tableColumns = tableColumns.map((col: any) => {
      return { ...col, customValue: () => <TextSkeleton /> }
    })
  }

  return (
    <Box
      flex={1}
      padding="30px 0px 30px 40px"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Stack flex={1}>
        <TableContainer
          sx={{
            maxHeight: tableMaxHeight,
          }}
        >
          <Table
            stickyHeader
            aria-label="collapsible table"
            sx={{
              '& .MuiTableHead-root': {
                '& .MuiTableRow-root': {
                  '& th': {
                    backgroundColor: 'transparent',
                    color: '#261F72',
                    fontSize: '15px',
                    fontWeight: '400',
                    '& svg': {
                      color: '#261F72',
                    },
                  },
                },
              },
              '& .MuiTableBody-root': {
                borderRadius: '3px',
                '& .MuiTableRow-root': {
                  '&.odd': {
                    td: {
                      backgroundColor: '#fff',
                    },
                  },
                  '&.even': {
                    td: {
                      backgroundColor: '#FAFBFF',
                    },
                  },
                  '& td': {
                    color: '#261F72',
                  },
                },
              },
              'th, td': {
                textAlign: 'left',
              },
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  'th:not(.alphabet-filter)': {
                    backgroundColor: '#fff!important',
                  },
                }}
              >
                {/* <TableCell padding="checkbox">
                  <CustomCheckbox
                    checked={false}
                    onChange={() => {}}
                    sx={{ ml: 2 }}
                  />
                </TableCell> */}
                {columns.map((column: any, i: number) => (
                  <TableCell key={`hd-${i}`} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
                {collapsible && <TableCell />}
                {withOptions && <TableCell />}
                <TableCell
                  className="alphabet-filter"
                  sx={{
                    borderBottom: 'unset!important',
                    backgroundColor: 'transparent!important',
                    width: '10px',
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row: any, index: number) => (
                <RowWrapper
                  key={`tbl-row-${index}`}
                  columns={tableColumns}
                  index={index}
                  row={row}
                  collapsible={collapsible}
                  withOptions={withOptions}
                  anchorEl={anchorEl}
                  contextMenu={contextMenu}
                  isContextMenuOpen={isContextMenuOpen}
                  handleOpenContextMenu={handleOpenContextMenu}
                  handleCloseContextMenu={handleCloseContextMenu}
                  activeRow={activeRow}
                  count={rows.length}
                  alphabetFilterComponent={
                    !isLoading && (
                      <AlphabetFilter
                        handleAlphaFilter={handleAlphaFilter}
                        isLoading={isLoading}
                      />
                    )
                  }
                  collapsibleComponent={collapsibleComponent}
                  isLoading={isLoading}
                />
              ))}
              {!isLoading && tableData.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={
                      tableColumns.length +
                      (collapsible ? 1 : 0) +
                      (!!contextMenu ? 1 : 0) +
                      1
                    }
                    sx={{
                      borderBottom: '1px solid transparent',
                    }}
                  >
                    <Typography textAlign="center">No Records.</Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell
                  colSpan={
                    tableColumns.length +
                    (collapsible ? 1 : 0) +
                    (!!contextMenu ? 1 : 0) +
                    1
                  }
                  sx={{ borderBottom: 'unset', pt: '10px', pb: '50px' }}
                >
                  <Stack
                    className="pagination"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                    sx={{
                      'span.label': {
                        fontSize: 14,
                      },
                    }}
                  >
                    <span className="label">
                      {isLoading ? (
                        `Fetching Records . . .`
                      ) : (
                        <>
                          Showing {pageFrom || 0} - {pageTo || 0} of{' '}
                          {total || 0} {entityLabel}
                        </>
                      )}
                    </span>
                    <Pagination
                      sx={{
                        visibility: isLoading ? 'hidden' : 'visible',
                      }}
                      count={pageCount}
                      size="medium"
                      page={currentPage}
                      onChange={(e, page) => {
                        onPageChange(page)
                      }}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  )
}

export default DesktopTable
