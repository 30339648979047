import { Box, FilledInput, FormControl, InputLabel } from '@mui/material'
import { CheckScreenSize } from '../../../helpers/utils'
import CustomButton from '../../customButton'

const PersonalInformation = (props: any) => {
  const { data, onChange, errors, onSubmit, isLoading } = props
  const isDesktop = CheckScreenSize('(min-width: 1200px)')

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      noValidate
      autoComplete="off"
      sx={{
        position: 'relative',
        height: '100%',

        '& h3': {
          m: 1,
          mb: 2,
        },
        '.MuiInputBase-root': {
          bgcolor: '#fcfcfc',
          border: '1px solid #D9D9D9',
          borderRadius: '10px',
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
        },
        '& input': {
          fontSize: '14px',
        },
      }}
    >
      <h3>Personal Information</h3>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <FormControl
          margin="normal"
          variant="filled"
          sx={{ m: 1, flexGrow: '1' }}
          error={errors.name}
        >
          <InputLabel htmlFor="component-filled">Name</InputLabel>
          <FilledInput
            disableUnderline={true}
            id="name"
            type="text"
            value={data?.name || ''}
            onChange={onChange('name')}
          />
        </FormControl>
        <FormControl
          margin="normal"
          variant="filled"
          sx={{ m: 1, flexGrow: '1' }}
          error={errors.email}
        >
          <InputLabel htmlFor="component-filled">Email Address</InputLabel>
          <FilledInput
            id="email"
            type="email"
            disableUnderline={true}
            value={data?.email || ''}
            onChange={onChange('email')}
          />
        </FormControl>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <FormControl
          margin="normal"
          variant="filled"
          sx={{ m: 1, width: isDesktop ? '48%' : '100%' }}
        >
          <InputLabel htmlFor="component-filled">Phone</InputLabel>
          <FilledInput
            disableUnderline={true}
            id="phone"
            type="text"
            value={data?.phone_number || ''}
            onChange={onChange('phone_number')}
            error={errors.phone_number}
          />
        </FormControl>
      </Box>

      <Box
        sx={{
          textAlign: 'right',
          marginTop: isDesktop ? '20px' : '30px',
          padding: '25px 0',
        }}
      >
        <CustomButton
          isLoading={isLoading}
          type="submit"
          disableElevation
          label="Save Changes"
          className="likester-button"
        />
      </Box>
    </Box>
  )
}

export default PersonalInformation
