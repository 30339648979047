import CloseIcon from '@mui/icons-material/Close'
import { capitalize } from 'lodash'
import { Box, Dialog, DialogContent, IconButton } from '@mui/material'

import SubscriptionSuccess from '../../../assets/images/subscription-sucess.png'
import {
  discountedTotal,
  selectedPackagesTotalRate,
} from '../../../helpers/utils'

export default function SubscribedModal({
  onToggle,
  isOpen,
  selectedSubscription,
}: {
  onToggle: () => void
  isOpen: boolean
  selectedSubscription: any
}) {
  const getService = (service: string) => {
    const arrService = service.split('_')
    return capitalize(arrService[arrService.length - 1] ?? '')
  }

  const getType = (service: string) => {
    const arrService = service.split('_')
    if (arrService.length > 1) {
      const arrType = arrService.slice(0, -1)
      return capitalize(arrType.join(' '))
    }

    return capitalize(arrService[arrService.length - 1] ?? '')
  }

  const selectedPackages = selectedSubscription?.items.map((i: any) => i.package)
  const totalRate = selectedPackagesTotalRate(selectedPackages)
  const grandTotal = discountedTotal(
    totalRate,
    selectedSubscription?.coupon?.discounted_rate ?? 0
  )

  return (
    <Dialog
      onClose={onToggle}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      classes={{
        paper: 'dialog-paper',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '15px',
        },
        '& img': {
          position: 'absolute',
          maxWidth: '450px',
          bottom: '-105px',
          zIndex: '99',
          right: '-42%',
        },
        '.dialog-paper': {
          overflow: 'inherit !important',
        },
      }}
    >
      <img src={SubscriptionSuccess} alt="" />
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          background: '#3791F0',
          color: '#fff',
          zIndex: '99',
          top: '-18px',
          right: '-18px',
        }}
        onClick={onToggle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          backgroundColor: '#4862FF',
          borderRadius: '15px',
          color: '#fff',
          width: '410px',
          p: ' 40px',
          '& h3': {
            marginTop: '0',
          },
        }}
      >
        <h3>Successfully Added!</h3>
        {selectedSubscription?.items &&
          selectedSubscription?.items.map((item: any) => {
            const type = getType(item.package.service)
            const service = getService(item.package.service)

            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& h4': {
                    margin: '0',
                    '& span': {
                      textTransform: 'lowercase',
                      fontWeight: 'normal',
                    },
                  },
                  '& p': {
                    fontSize: '12px',
                  },
                }}
              >
                <Box sx={{ mb: '30px' }}>
                  <Box sx={{ mb: '30px' }}>
                    <p>Types of {service}</p>
                    <h4>{getType(type)}</h4>
                  </Box>
                  <Box>
                    <p>Amount of {service}</p>
                    <h4>
                      {item.package.quantity} {service} <span>per post</span>
                    </h4>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <p>Subscription Fee</p>
                    <h4>
                      <span>$</span> {item.package.rate}
                      <span>/ month</span>
                    </h4>
                  </Box>
                </Box>
              </Box>
            )
          })}
        <hr />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            '& h4': {
              margin: '0',
              '& span': {
                textTransform: 'lowercase',
                fontWeight: 'normal',
              },
            },
            '& p': {
              fontSize: '12px',
            },
            mb: '30px',
          }}
        >
          <Box>
            <p>Discount</p>
            <h4>
              <span>
                {selectedSubscription?.coupon?.discount_percentage ?? 0}
              </span>{' '}
              %
            </h4>
          </Box>
          <Box>
            <p>Grand Total</p>
            <h4>
              <span>$</span> {grandTotal}
            </h4>
          </Box>
        </Box>
        <hr />
        <Box
          sx={{
            '& b': {
              textDecoration: 'underline',
            },
          }}
        >
          <p>Any questions or remarks do you have in mind?</p>
          <b>Just write us a message!</b>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
