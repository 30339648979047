import {
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const OptionPopover = (props: any) => {
  const {
    row,
    isContextMenuOpen,
    anchorEl,
    handleCloseContextMenu,
    contextMenu,
    activeRow,
    handleOpenContextMenu,
    sx = {},
    disabled,
  } = props

  const hasContext = contextMenu.length > 0
  return (
    <>
      <Button
        key={`context-menu-button-${row.id}`}
        id={`context-menu-button-${row.id}`}
        aria-controls={isContextMenuOpen ? `context-menu-${row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={isContextMenuOpen ? 'true' : undefined}
        onClick={(e) => {
          hasContext && handleOpenContextMenu(e, row)
        }}
        className={
          isContextMenuOpen && activeRow?.id === row?.id ? 'active' : ''
        }
        disabled={disabled}
        sx={{
          backgroundColor: '#f4f7ff',
          color: '#4863ff',
          minWidth: 'unset',
          padding: '5px 6px',
          '&.active': {
            backgroundColor: '#4863ff',
            color: '#f4f7ff',
          },
          borderRadius: '10px',
          ...sx,
        }}
      >
        <MoreHorizIcon />
      </Button>
      <Popover
        key={`context-menu-popover-${row.id}`}
        id={`context-menu-popover-${row.id}`}
        open={isContextMenuOpen}
        anchorEl={anchorEl}
        onClose={handleCloseContextMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            mt: '4px',
            ml: '10px',
            borderRadius: '8px',
            border: '1px solid #F4F8FF',
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.01)',
          },
          '& .MuiList-root': {
            paddingTop: '5px',
            paddingBottom: '5px',
            '& .MuiCheckbox-root': {
              padding: '3px',
            },
            '& li': {
              fontSize: '16px',
              color: '54595E',
              p: '5px 15px',
              '&:hover': {
                backgroundColor: '#F4F7FE',
                color: '#261F72',
                cursor: 'pointer',
              },
              '& .MuiTypography-root': {
                fontSize: '14px',
              },
            },
          },
        }}
      >
        <Typography sx={{ paddingTop: '10px', paddingLeft: '15px' }}>
          Actions
        </Typography>
        <List>
          {contextMenu.map((context: any, index: number) => {
            if (context?.isHidden?.(activeRow)) {
              return null
            }
            return (
              <ListItem
                key={`context-list-item-desktop-${index}-${row.id}`}
                disablePadding
                onClick={() => {
                  context?.callBack?.(activeRow)
                  handleCloseContextMenu()
                }}
              >
                <ListItemText
                  sx={{
                    pl: 3.5,
                    position: 'relative',
                    svg: {
                      position: 'absolute',
                      left: 0,
                      bottom: 2,
                      fontSize: 20,
                    },
                    '&:hover': {
                      svg: {
                        color: '#ff4ea3',
                      },
                    },
                  }}
                >
                  {context.icon && context.icon}
                  {context.label}
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </>
  )
}

export default OptionPopover
