export const USER_STATUS_ACTIVE = 'active'
export const STATUS_PAUSED = 'paused'
export const USER_STATUS_CANCELLED = 'cancelled'
export const USER_STATUS_SUSPENDED = 'suspended'
export const USER_STATUS_BANNED_IP = 'banned_ip'
export const USER_STATUS_BANNED = 'banned'

export const statuses = {
  [USER_STATUS_CANCELLED]: 'Cancelled',
  [STATUS_PAUSED]: 'Paused',
  [USER_STATUS_ACTIVE]: 'Active',
}

export const couponStatuses = {
  expired: 'Expired',
  scheduled: 'Scheduled',
  active: 'Active',
  saturated: 'Saturated',
}

export const userStatuses = {
  [USER_STATUS_BANNED]: 'Banned',
  [USER_STATUS_SUSPENDED]: 'Suspended',
  [USER_STATUS_ACTIVE]: 'Active',
  [USER_STATUS_BANNED_IP]: 'Banned IP Address',
}

export const rangeOfLikes = {
  '50': [10, 50],
  '100': [50, 100],
  '250': [50, 250],
  '500': [100, 500],
  '1000': [500, 1000],
  '1500': [500, 1500],
}
