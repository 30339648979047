import { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box/Box'
import Card from '@mui/material/Card/Card'
import Stack from '@mui/material/Stack/Stack'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CircularProgress } from '@mui/material'

import { useUpdateAccountLikesProperties } from '../../services/subscription.service'
import CustomSwitch from '../customSwitch'
import { AppContext } from '../../Protected'
import { rangeOfLikes as rangeOfLikesOptions } from '../../constants'
import CustomDialog from '../customDialog'
import CustomInput from '../customInput'
import CustomButton from '../customButton'
import { useGetInstagramProfile } from '../../services/instagram.service'
import { useAddAccountProfile } from '../../services/account.service'
import useAccountStore from '../../store/account.store'
import { formatNumber } from '../../utils/format-number.util'
import { useGetUserAccounts } from '../../services/auth.service'
import Profile from '../dashboard/profile'
import DashboardLoading from '../dashboardLoading'

const parseIgUrl = (url: string) => {
  return 'https://www.followerpackages.com/services/image_orig.php?url=' + url
}

export default function CustomerAddon() {
  const { setNotification, isDesktop, isSmallScreen } = useContext(AppContext)
  const { selectedAccount, setSelectedAccount, setAccounts } = useAccountStore()
  const queryParams = new URLSearchParams(window.location.search)
  const subscriptionId = queryParams?.get('subscription')
  const accountId = queryParams?.get('account')

  const { data: accounts, refetch, isLoading } = useGetUserAccounts()

  const [likesDelay, setLikesDelay] = useState<number | number[]>(5)
  const [likesRange, setLikesRange] = useState<number[] | null>([10, 50])
  const [likesDelayToBeSaved, setLikesDelayToBeSaved] = useState<
    number | number[] | null
  >(null)
  const [likesRangeToBeSaved, setLikesRangeToBeSaved] = useState<
    number[] | null
  >(null)
  const [likesRangeMinMax, setLikesRangeMinMax] = useState<number[]>([0, 100])

  const [likesQuantity, setLikesQuantity] = useState<number | null>(null)
  const [hasLikesSubscription, setHasLikesSubscription] = useState(false)
  const [showAddProfile, setShowAddProfile] = useState(false)
  const [username, setUsername] = useState('')
  const [hasError, setHasError] = useState(false)
  const [addedId, setAddedId] = useState<number | null>(null)

  const { mutate: updateLikesProperites } = useUpdateAccountLikesProperties()

  const { isLoading: addingProfile, mutate: addProfile } =
    useAddAccountProfile()

  //comment for the moment so it will not consume the instagram api resources
  const {
    data: igProfileData,
    refetch: searchIgProfile,
    isFetching,
  } = useGetInstagramProfile(username)

  useEffect(() => {
    setAccounts(accounts)
  }, [accounts, setAccounts])

  useEffect(() => {
    const addedAccount = accounts?.find((a: any) => a.id === addedId)
    if (addedId && addedAccount) {
      setSelectedAccount(addedAccount)
    }
  }, [addedId, accounts, setSelectedAccount])

  useEffect(() => {
    if (accounts && accountId && subscriptionId) {
      const account = accounts.find((p: any) => p.id === +accountId!)
      setSelectedAccount(account)
      return
    }
    if (accounts && !selectedAccount && accounts[0]) {
      setSelectedAccount(accounts[0])
    }
  }, [subscriptionId, accountId, accounts, setSelectedAccount, selectedAccount])

  useEffect(() => {
    if (selectedAccount) {
      const subs = selectedAccount.subscriptions
      const items = subs[0]?.items ?? []
      const likesPackage = items.find((item: any) =>
        item.package?.service.includes('likes')
      )
      const isSubscriptionLike = !!likesPackage

      if (
        selectedAccount?.subscriptions &&
        selectedAccount?.subscriptions?.length
      ) {
        setLikesQuantity(likesPackage?.package?.quantity ?? null)
      }

      const { delay_of_likes, range_of_likes } = selectedAccount

      setLikesDelay(isSubscriptionLike ? delay_of_likes : null)
      setLikesDelayToBeSaved(delay_of_likes ?? null)
      setLikesRange(
        range_of_likes ? [range_of_likes?.start, range_of_likes?.end] : null
      )
      setLikesRangeToBeSaved(
        range_of_likes ? [range_of_likes?.start, range_of_likes?.end] : null
      )

      setHasLikesSubscription(isSubscriptionLike)
    }
  }, [selectedAccount])

  useEffect(() => {
    if (likesQuantity) {
      setLikesRangeMinMax(
        (rangeOfLikesOptions as any)[`${likesQuantity}`] as number[]
      )
    }
  }, [likesQuantity])

  const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchAccount()
    }
  }

  const handleUpdate = (delay: any, range: any) => {
    updateLikesProperites(
      {
        id: selectedAccount?.id,
        payload: {
          delay_of_likes: delay,
          range_of_likes: range
            ? {
                start: range[0],
                end: range[1],
              }
            : null,
        },
      },
      {
        onSuccess: () => {
          setNotification('Successfully updated likes settings.')
          refetch()
        },
      }
    )
  }

  const onChangeLikesDelay = (
    event: React.SyntheticEvent | Event,
    newValue: number | number[],
    commited = false
  ) => {
    setLikesDelay(newValue)
    if (commited) {
      setLikesDelayToBeSaved(newValue)
      handleUpdate(newValue, likesRangeToBeSaved)
    }
  }

  const onChangeLikesRange = (
    event: React.SyntheticEvent | Event,
    newValue: number | number[],
    commited = false
  ) => {
    setLikesRange(newValue as number[])
    if (commited) {
      setLikesRangeToBeSaved(newValue as number[])
      handleUpdate(likesDelayToBeSaved, newValue)
    }
  }

  const onClickAddProfile = (e: any) => {
    e.preventDefault()
    if (!username) {
      setHasError(true)
      return
    }

    setHasError(false)
    addProfile(
      { user_name: username },
      {
        onSuccess: (response) => {
          setAddedId(response?.data?.data?.id)
          setNotification('Account successfully added.')
          setShowAddProfile(false)
          setUsername('')
          refetch()
        },
      }
    )
  }

  const handleSearchAccount = () => {
    searchIgProfile()
  }

  const user = igProfileData?.user

  if (isLoading) {
    return <DashboardLoading isDesktop={isDesktop} />
  }

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6} md={2}>
        <Card
          sx={{
            p: '7px 12px 12px',
            borderRadius: '10px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1.5}
          >
            <Typography color="#261F72" fontSize={15} fontWeight={600}>
              Delay of Likes
            </Typography>
            <CustomSwitch
              customStyles={{
                '& .custom-switch': {
                  mr: '-12px',
                },
              }}
              checked={!!likesDelay}
              disabled={!hasLikesSubscription}
              onChange={(e) => {
                const checked = e.target.checked
                setLikesDelay(checked ? 5 : 0)
                handleUpdate(checked ? 5 : null, likesDelay)
              }}
            />
          </Stack>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={1.5}
            >
              <Typography color="#261F72" fontSize={32} fontWeight={800}>
                {likesDelay ?? '0'}
              </Typography>
              <Typography color="#261F72" fontSize={22} fontWeight={700} ml={1}>
                mins
              </Typography>
            </Stack>
            <Slider
              defaultValue={5}
              aria-label="Default"
              min={5}
              max={60}
              value={likesDelay as any}
              onChange={(e, value) => onChangeLikesDelay(e, value, false)}
              onChangeCommitted={(e, value) =>
                onChangeLikesDelay(e, value, true)
              }
              disabled={!hasLikesSubscription || likesDelay === null}
              sx={{
                color: '#FF4EA3',
                '.MuiSlider-thumb': {
                  boxShadow:
                    '0px 3px 1px 0px rgba(0,0,0,0.2),0px 1px 1px 2px rgba(0,0,0,0.14),0px 1px 3px 1px rgba(0,0,0,0.12)!important',
                  color: '#fff',
                  width: '15px',
                  height: '15px',
                },
              }}
            />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={6} md={2}>
        <Card
          sx={{
            p: '7px 15px 15px',
            borderRadius: '10px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1.5}
          >
            <Typography color="#261F72" fontSize={15} fontWeight={600}>
              Range of Likes
            </Typography>
            <CustomSwitch
              customStyles={{
                '& .custom-switch': {
                  mr: '-12px',
                },
              }}
              disabled={!hasLikesSubscription}
              checked={!!likesRange}
              onChange={(e) => {
                const checked = e.target.checked
                setLikesRange(checked ? likesRangeMinMax : null)
                setLikesRangeToBeSaved(checked ? likesRangeMinMax : null)
                handleUpdate(likesDelay, checked ? likesRangeMinMax : null)
              }}
            />
          </Stack>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={1.5}
            >
              <Typography color="#261F72" fontSize={32} fontWeight={800}>
                {likesRange ? likesRange[0] : 0} -{' '}
                {likesRange ? likesRange[1] : 0}
              </Typography>
            </Stack>
            <Slider
              defaultValue={12}
              value={likesRange as any}
              min={likesRangeMinMax[0]}
              max={likesRangeMinMax[1]}
              onChange={(e, value) => onChangeLikesRange(e, value, false)}
              onChangeCommitted={(e, value) =>
                onChangeLikesRange(e, value, true)
              }
              disabled={!hasLikesSubscription || likesRange === null}
              sx={{
                color: '#FF4EA3',
                '.MuiSlider-thumb': {
                  boxShadow:
                    '0px 3px 1px 0px rgba(0,0,0,0.2),0px 1px 1px 2px rgba(0,0,0,0.14),0px 1px 3px 1px rgba(0,0,0,0.12)!important',
                  color: '#fff',
                  width: '15px',
                  height: '15px',
                },
              }}
            />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} md={8} pr={4} mt={isSmallScreen ? 3 : 0}>
        <Card
          sx={{
            p: '15px',
            borderRadius: '10px',
            width: '100%',
            height: '133px',
          }}
        >
          <Typography color="#261F72" fontSize={15} fontWeight={600} mb={1}>
            Instagram Profiles
          </Typography>
          <Box overflow="auto">
            <Stack direction="row">
              <Profile
                isAdd
                onAddProfile={() => {
                  setShowAddProfile(true)
                }}
              />
              {accounts?.map((profile: any, index: number) => {
                return (
                  <Profile
                    key={`profile${index}`}
                    profile={profile}
                    isSelected={selectedAccount?.id === profile?.id}
                  />
                )
              })}
            </Stack>
          </Box>
        </Card>
      </Grid>
      <CustomDialog
        open={showAddProfile}
        title="Add Instagram Profile"
        onClose={() => {
          setShowAddProfile(false)
          setUsername('')
        }}
        isDesktop={isDesktop}
      >
        <Typography fontSize="20" fontWeight="bold" mb={2}>
          Instagram Username
        </Typography>
        <CustomInput
          label="Username"
          placeholder="@username"
          value={username}
          color="#51459E"
          onChange={onChangeUsername}
          onKeyDown={onKeyDown}
          required
          error={hasError && !username}
          disabled={isFetching}
          parentSx={{
            padding: '10px 15px',
            position: 'relative',
            border: '2px solid #4862FF',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  color: '#00B67A',
                  position: 'absolute',
                  right: 0,
                  bottom: '20px',
                  fontSize: isDesktop ? '16px' : '14px',
                  '& .search-text': {
                    display: isSmallScreen ? 'none' : 'block',
                  },
                }}
              >
                {isFetching || igProfileData ? (
                  <>
                    <span className="search-text">
                      {isFetching ? 'Searching Account' : 'Account Found'}{' '}
                    </span>
                    &nbsp;&nbsp;
                    {isFetching ? (
                      <CircularProgress
                        data-testid="button-loading-icon"
                        sx={{
                          width: isSmallScreen
                            ? '25px!important'
                            : '15px!important',
                          height: isSmallScreen
                            ? '25px!important'
                            : '15px!important',
                          mr: '5px!important',
                        }}
                      />
                    ) : (
                      <CheckCircleIcon
                        sx={{ fontSize: isSmallScreen ? '25px' : '15px' }}
                      />
                    )}
                  </>
                ) : (
                  <CustomButton
                    className="likester-button"
                    label="Search"
                    sx={{
                      fontSize: '14px!important',
                      p: '4px!important',
                    }}
                    isLoading={isFetching}
                    disabled={isFetching || !username}
                    onClick={handleSearchAccount}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />

        {user && (
          <>
            <Box
              sx={{
                background: '#FAFBFF',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: isDesktop ? '10px 50px 10px 20px' : '10px',
                mt: 2,
                '& h4': {
                  margin: '0',
                },
                '& p': {
                  margin: '0',
                },
                '& .profile': {
                  borderRadius: '50%',
                  width: isDesktop ? '100px' : '50px',
                  height: isDesktop ? '100px' : '50px',
                  backgroundImage: `url(${parseIgUrl(user?.profile_pic_url)})`,
                  backgroundSize: 'cover',
                },
              }}
            >
              <div className="profile" />
              <div>
                <h4>
                  {formatNumber(user?.edge_owner_to_timeline_media?.count)}
                </h4>
                <p>Posts</p>
              </div>
              <div>
                <h4>{formatNumber(user?.edge_followed_by?.count)}</h4>
                <p>Followers</p>
              </div>
              <div>
                <h4>{formatNumber(user?.edge_follow?.count)}</h4>
                <p>Following</p>
              </div>
            </Box>
          </>
        )}
        {igProfileData && !user ? (
          <Box
            sx={{
              color: 'red',
              mt: '10px',
            }}
          >
            Profile not found
          </Box>
        ) : null}
        <CustomButton
          className="likester-button"
          label="Add Profile"
          sx={{
            mt: 2,
            float: 'right',
            width: isDesktop ? 'unset' : '100%',
            p: '12px 20px 10px',
          }}
          isLoading={addingProfile}
          disabled={isFetching || addingProfile || !user}
          onClick={onClickAddProfile}
        />
      </CustomDialog>
    </Grid>
  )
}
