import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Radio,
  TextField,
} from '@mui/material'
import { useEffect } from 'react'
import CreditDebitImg from '../../../assets/images/credit_debit-cards.png'
import ApplePayImg from '../../../assets/images/apple-pay.png'
import { useCheckCoupon } from '../../../services/coupon.service'
import CustomButton from '../../customButton'

const PaymentInformation = (props: any) => {
  const { setCoupon, onChange, payload, errors, isDesktop } = props
  const {
    data: coupon,
    refetch: checkCoupon,
    isFetching,
  } = useCheckCoupon(payload.code)

  const applyCoupon = () => {
    if (payload.code) {
      checkCoupon()
    }
  }

  useEffect(() => {
    if (coupon) {
      setCoupon(coupon)
    }
  }, [coupon]) //eslint-disable-line

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        '& h2': {
          color: '#261F72',
          fontSize: '24px',
        },
      }}
    >
      <h2>Payment Information</h2>
      <p>
        Creating an account will help you manage your subscription and orders in
        to our All-in-one User portal.
      </p>
      <Box>
        <TextField
          label=" Name"
          variant="filled"
          fullWidth
          placeholder="Full Name"
          InputLabelProps={{
            shrink: true,
          }}
          error={errors.name}
          onChange={onChange('name')}
          value={payload.name}
          sx={{
            m: isDesktop ? '10px' : '5px auto',
            ml: 'unset',
            boxShadow: 'unset',
            '& .MuiInputBase-root': {
              border: '1px solid #D9D9D9',
              borderRadius: '10px',
              background: '#FFFFFF',
              '&:before': {
                border: 'none',
                content: 'none',
              },
              '&:after': {
                border: 'none',
              },
              '&.Mui-focused': {
                border: '2px solid #4862FF',
              },
            },
          }}
        />

        <Box
          sx={{
            '& .header': {
              display: isDesktop ? 'flex' : 'block',
              alignItems: 'center',
              justifyContent: 'space-between',
              '& img.applepay': {
                width: '40px',
              },
            },
          }}
        >
          <div className="header">
            <div>
              <FormControlLabel
                control={<Radio />}
                checked={payload.payment_type === 'card'}
                onChange={onChange('payment_type', 'card')}
                label="Credit Card / Debit Card"
              />
            </div>
            <img src={CreditDebitImg} alt="Credit Card / Debit Card" />
          </div>
          <div className="header">
            <div>
              <FormControlLabel
                control={<Radio />}
                checked={payload.payment_type === 'applepay'}
                onChange={onChange('payment_type', 'applepay')}
                label="Apple Pay"
              />
            </div>
            <img src={ApplePayImg} alt="ApplePay" className="applepay" />
          </div>
        </Box>
      </Box>

      <h2>Coupon Code</h2>
      <TextField
        label=" Coupon"
        variant="filled"
        fullWidth
        placeholder="eg: BlackFriday"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CustomButton
                disabled={isFetching}
                onClick={applyCoupon}
                disableElevation
                isLoading={isFetching}
                label={isFetching ? "Validating" : "Apply"}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        error={errors.code}
        onChange={onChange('code')}
        value={payload.code}
        sx={{
          margin: isDesktop ? '10px' : '5px auto',
          ml: 'unset',
          boxShadow: 'unset',
          '& .MuiInputBase-root': {
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            background: '#FFFFFF',
            '&:before': {
              border: 'none',
              content: 'none',
            },
            '&:after': {
              border: 'none',
            },
            '&.Mui-focused': {
              border: '2px solid #4862FF',
            },
          },
          button: {
            bgcolor: '#00B67A',
            color: '#fff',
            fontSize: '14px',
            textTransform: 'none',
            borderRadius: '8px',
            padding: '5px 10px',
            '&:hover': {
              bgcolor: '#00B67A',
              color: '#fff',
              opacity: '0.8',
            },
          },
        }}
      />
    </Box>
  )
}

export default PaymentInformation
