import { Box, Divider, Grid, Tab } from '@mui/material'
import { ChangeEvent, useEffect, useState, useContext } from 'react'
import { TabPanel, TabList, TabContext } from '@mui/lab'

import { serviceTypes } from '../../../types/subscription.types'
import Pricing from './pricing'
import { useGetPackageOptions } from '../../../services/package.service'
import PaymentInformation from './payment-information'
import OrderSummary from './order-summary'
import NoPost from './no-post'
import CustomButton from '../../customButton'
import { AppContext } from '../../../Protected'
import { useAccountCheckout } from '../../../services/account.service'
import { apiResponseSimplify } from '../../../helpers/utils'

const AddSubscription = (props: any) => {
  const { account, selectedSubscription } = props
  const { isDesktop } = useContext(AppContext)

  const { data: packages, refetch } = useGetPackageOptions()

  const [activeTab, setActiveTab] = useState('comments')
  const [selectedPackages, setSelectedPackages] = useState({})
  const [coupon, setCoupon] = useState<any>(null)
  const [addSubscription, setAddSubscription] = useState(false)

  const { isLoading: checkoutLoading, mutate: checkout } = useAccountCheckout(
    account?.id
  )

  useEffect(() => {
    refetch()
  }, []) //eslint-disable-line

  useEffect(() => {
    const p = Object.values(selectedPackages).filter((p: any) => p !== null)
    if (p.length) {
      const packageIds = p.map((i: any) => i.id)
      onChange('packages', packageIds)()
    } else {
      onChange('packages', [])()
    }
  }, [selectedPackages])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }

  const onSelectPackage = (servicePackage: any | null, type: string) => {
    setSelectedPackages({
      ...selectedPackages,
      [type]: servicePackage,
    })
  }

  const defaultErrors = {
    name: false,
    packages: false,
    code: false,
  }
  const defaultPayload = {
    name: '',
    packages: [],
    code: '',
    country: 'US',
    payment_type: 'card',
  }

  const [errors, setErrors] = useState(defaultErrors)
  const [payload, setPayload] = useState(defaultPayload)

  const onChange =
    (name: string, customValue?: any) =>
    (event?: ChangeEvent<HTMLInputElement>) => {
      const value = customValue ?? event?.target.value
      setPayload((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }

  const validate = () => {
    let dataFields = [
      {
        key: 'packages',
        isValid: payload.packages.length > 0,
      },
      {
        key: 'name',
        isValid: payload.name !== '',
      },
    ]

    return {
      data: dataFields,
      isValid: dataFields.every((data) => data.isValid),
    }
  }

  const onSubmit = () => {
    const validation = validate()

    if (!validation.isValid) {
      setErrors(
        validation.data.reduce((prevField, field) => {
          return { ...prevField, [field.key]: !field.isValid }
        }, defaultErrors)
      )
      return
    }

    checkout(payload, {
      onSuccess: (response) => {
        const data = apiResponseSimplify(response, true)
        window.location.replace(data?._links?.redirect?.href)
      },
    })
  }

  return addSubscription || selectedSubscription ? (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        pt: 2,
        '& h2': {
          color: '#261F72',
          fontSize: '24px',
        },
      }}
    >
      <h2>Add a subscription</h2>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '10px',
        }}
      >
        <TabContext value={activeTab}>
          <Box
            sx={{
              borderBottom: '1px solid #f0f1fa',
              borderColor: 'divider',
              button: {
                wordWrap: 'normal',
                width: '32%',
                padding: '0px 20px',
              },
            }}
          >
            <TabList onChange={handleChangeTab} centered>
              {Object.values(serviceTypes).map((type: any) => {
                return (
                  <Tab
                    key={`tab-${type.value}`}
                    label={<p className="p-label">{type.label}</p>}
                    value={type.value}
                    sx={{
                      textTransform: 'capitalize',
                      padding: isDesktop ? '15px 40px' : 'unset',
                      fontSize: isDesktop ? '20px' : '14px',
                    }}
                  />
                )
              })}
            </TabList>
          </Box>
          {Object.values(serviceTypes).map((type: any) => {
            return (
              <TabPanel
                key={`tab=panel-${type.value}`}
                value={type.value}
                sx={{
                  width: isDesktop ? '80%' : 'unset',
                  margin: '0 auto',
                  p: isDesktop ? '24px' : '12px',
                }}
              >
                <Pricing
                  packages={packages?.data ?? []}
                  serviceType={type.value}
                  onSelectPackage={(p: any | null) =>
                    onSelectPackage(p, type.value)
                  }
                  selectedPackage={(selectedPackages as any)[type.value]}
                />
              </TabPanel>
            )
          })}
        </TabContext>

        <Divider />
        <Grid container spacing={isDesktop ? 2 : 0} p="30px 40px">
          <Grid item xs={12} md={8}>
            <PaymentInformation
              setCoupon={setCoupon}
              payload={payload}
              onChange={onChange}
              onSubmit={onSubmit}
              errors={errors}
              isDesktop={isDesktop}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {Object.values(selectedPackages).filter((e) => e).length > 0 ? (
              <OrderSummary
                selectedPackages={selectedPackages as any}
                onSelectPackage={onSelectPackage}
                coupon={coupon}
                setCoupon={setCoupon}
                onChange={onChange}
                isDesktop={isDesktop}
              />
            ) : (
              <Box
                sx={{
                  padding: '70px 50px',
                }}
              >
                No Subscription selected.
              </Box>
            )}
          </Grid>
        </Grid>
        <Divider />

        <Box
          sx={{
            p: '25px',
            pb: isDesktop ? 5 : 20,
            display: 'block',
            textAlign: 'right',
            backgroundColor: '#fff',
            '.MuiButtonBase-root.MuiCheckbox-root': {
              color: '#00B67A',
            },
          }}
        >
          <CustomButton
            className="likester-button"
            label="Proceed to Payment"
            disableElevation
            sx={{ width: isDesktop ? 'unset' : '100%' }}
            onClick={() => onSubmit()}
            isLoading={checkoutLoading}
          />
        </Box>
      </Box>
    </Box>
  ) : account ? (
    <NoPost setAddSubscription={setAddSubscription} />
  ) : null
}

export default AddSubscription
